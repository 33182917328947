<template>
  <div class="fileinput__wrapper">
    <span
      class="fileinput__button"
      :class="{ 'fileinput__button--disabled': disabled }"
      @click="onSelectFile"
      >{{ buttonText }}</span
    >
    <input
      ref="fileinput"
      class="fileinput"
      type="file"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      @change="onChangeFile"
    />
    <span
      class="fileinput__file-info"
      :class="{ 'fileinput__file-info--linked': hasFiles }"
      @click="onDownloadFile"
      >{{ fileInfo }}</span
    >
    <button
      v-show="hasFiles"
      class="fileinput__cancel-button"
      type="button"
      :tabindex="buttonTabindex"
      @click="onDeleteFilte"
    >
      <sm-icon class="fileinput__icon" name="cancel-circle" outline />
    </button>
  </div>
</template>

<script>
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SmFileinput',

  components: {
    SmIcon,
  },

  props: {
    value: {
      type: [String, Object],
      default: '',
    },

    buttonText: {
      type: String,
      default: 'Выберите файл',
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    buttonTabindex: {
      type: Number,
      default: -1,
    },

    accept: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: '',
    };
  },

  computed: {
    hasFiles() {
      return (
        !!this.files.length ||
        (this.value && typeof this.value === 'object' && this.value.fileName) ||
        (this.value && typeof this.value === 'string')
      );
    },

    fileInfo() {
      if (this.files.length > 1) return `Число файлов: ${this.files.length}`;
      if (this.files.length === 1) return this.files[0].name;
      if (this.value && typeof this.value === 'object' && this.value.fileName)
        return this.value.fileName;
      if (this.value && typeof this.value === 'string') return this.value;
      return 'Файл не выбран';
    },
  },

  methods: {
    onSelectFile() {
      const fileInput = this.$refs.fileinput;
      let clickEvent = new MouseEvent('click', { bubbles: true });
      fileInput.dispatchEvent(clickEvent);
    },

    onChangeFile() {
      this.files = this.$refs.fileinput.files;
      this.$emit('changeFile', this.files);
    },

    onDownloadFile() {
      this.$emit('downloadFile');
    },

    onDeleteFilte() {
      this.$refs.fileinput.value = '';
      this.files = '';
      this.$emit('removeFile');
    },
  },
};
</script>

<style lang="scss">
.fileinput {
  display: none;
}

.fileinput__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fileinput__button {
  display: inline-flex;
  align-items: center;

  height: 36px;
  padding: 10px 55px;
  margin-right: 20px;

  font-size: 16px;
  line-height: 1;
  color: var(--white);

  border-radius: 5px;
  background-color: var(--blue);

  transition: background 0.3s;

  cursor: pointer;
}

.fileinput__button--disabled {
  background-color: var(--gray);

  // cursor: not-allowed;
}

.fileinput__file-info {
  color: var(--gray);

  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}

.fileinput__file-info--linked {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.fileinput__cancel-button {
  padding-left: 5px;
  z-index: 2;

  font-size: 0;

  border: none;
  background-color: transparent;

  cursor: pointer;
}

.fileinput__icon {
  color: var(--gray);

  transition: color 0.3s;

  &:hover {
    color: var(--red);

    transition: color 0.3s;
  }
}
</style>
